<template>
  <div class="page-index">
    <!-- PC -->
    <swiper class="swiper pc-class" :options="swiperOption" ref="swiper">
      <swiper-slide class="swiper-slide-first">
        <div class="content-oneImg">
          <img
            src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/seal-one.png"
            alt=""
          />
        </div>
        <div class="content-first">
          <div>
            <img
              class="text-oneImg"
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-img11.png"
              alt=""
            />
            <img
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/seal-tow.png"
              alt=""
            />
          </div>
          <div class="cont-text">
            <p>之美。</p>
            <p class="cont-textOne">融古铄今，唯一艺术平台正在呈现故宫别样</p>
            <p>传播中华民族五千年灿烂文明。</p>
            <p>文化精神内涵与现代审美相结合，向全世界</p>
            <p>依托故宫博大精深的文化内涵，将中国传统</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide-second">
        <div class="content-towImg">
          <img
            class="circle-towImg"
            src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/circle-one.png"
            alt=""
          />
          <img
            src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/text-tow.png"
            alt=""
          />
        </div>
        <div class="content-first">
          <div>
            <div
              v-for="(item, index) in contimgList.slice(0, 2)"
              :key="`${index}_img`"
            >
              <img :src="item.src" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
          <div>
            <div
              v-for="(item, index) in contimgList.slice(2, 4)"
              :key="`${index}_img1`"
            >
              <img :src="item.src" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide-three">
        <div class="content-first">
          <div>
            <div
              v-for="(item, index) in contimgList.slice(4, 6)"
              :key="`${index}_img2`"
            >
              <img :src="item.src" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
          <div>
            <div
              v-for="(item, index) in contimgList.slice(6, 8)"
              :key="`${index}_img3`"
            >
              <img :src="item.src" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide-four">
        <div class="content-towImg">
          <img
            class="circle-towImg"
            src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/circle-one.png"
            alt=""
          />
          <img
            src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/text-three.png"
            alt=""
          />
        </div>
        <div class="content-first">
          <p><span>玩法一</span>3张同款静态图合成该款动态图</p>
          <div class="cont-Img">
            <img
              class="cont-imgOne"
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/fs-oneImg.png"
              alt=""
            />
            <img
              class="cont-imgTow"
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/fs-oneImg.png"
              alt=""
            />
            <img
              class="cont-imgThree"
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/fs-oneImg.png"
              alt=""
            />
          </div>
          <div class="cont-fourImg">
            <img
              class="right-oneImg"
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/right-oneImg.png"
              alt=""
            />
            <div class="cont-contImg">
              <img
                class="gif-oneImg"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/gif-oneImg.png"
                alt=""
              />
              <img
                class="four-imgsmall"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/four-imgsmall.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide-five">
        <div class="content-first">
          <p><span>玩法二</span>8张不同款动态图合成1张完整动态大图</p>
          <div class="cont-Img">
            <div>
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc8.png"
                alt=""
              />
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc5.png"
                alt=""
              />
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc6.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc1.png"
                alt=""
              />
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc7.png"
                alt=""
              />
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc4.png"
                alt=""
              />
            </div>
            <div class="gif-contImg">
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc2.png"
                alt=""
              />
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc3.png"
                alt=""
              />
              <div class="git-cont">
                <img
                  class="gif-bigImg"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/gif-bigImg.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide-six">
        <div class="content-first">
          <div class="down-oneImg">
            <img
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/down-oneImg.png"
              alt=""
            />
          </div>
          <img
            class="qt-img"
            src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/qt-img.png"
            alt=""
          />
          <img
            class="gif-bigImg"
            src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/gif-bigImg.png"
            alt=""
          />
          <div class="foot-contImg">
            <div>
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/foot-oneIMg.png"
                alt=""
              />
            </div>
            <div>
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/foot-towImg.png"
                alt=""
              />
            </div>
            <div class="wx-code">
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/wx-code.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <!-- phone -->
    <div class="container phone-class">
      <div class="phone-first">
        <div class="container-cont">
          <div class="content-oneImg">
            <img
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-one.png"
              alt=""
            />
          </div>
          <div class="content-first">
            <div class="cont-contImg">
              <img
                class="phone-textOne"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-img13.png"
                alt=""
              />
              <img
                class="seal-tow"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-img3.png"
                alt=""
              />
            </div>
            <div class="cont-text">
              <p>在呈现故宫别样之美。</p>
              <p class="cont-textOne">融古铄今，唯一艺术平台正</p>
              <p>烂文明。</p>
              <p>世界传播中华民族五千年灿</p>
              <p>涵与现代审美相结合，向全</p>
              <p>涵，将中国传统文化精神内</p>
              <p>依托故宫博大精深的文化内</p>
            </div>
          </div>
        </div>
      </div>
      <div class="phone-second">
        <div class="container-cont">
          <div class="content-towImg">
            <img
              class="circle-towImg"
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/circle-one.png"
              alt=""
            />
            <img
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-img5.png"
              alt=""
              class="text-tow"
            />
          </div>
          <div class="content-first">
            <div class="img-cont">
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone1.png"
                  alt=""
                />
                <p>《国潮故宫冰嬉图1》</p>
              </div>
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone2.png"
                  alt=""
                />
                <p>《国潮故宫冰嬉图2》</p>
              </div>
            </div>
            <div class="img-cont">
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone3.png"
                  alt=""
                />
                <p>《国潮故宫冰嬉图3》</p>
              </div>
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone4.png"
                  alt=""
                />
                <p>《国潮故宫冰嬉图4》</p>
              </div>
            </div>
            <div class="img-cont">
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone5.png"
                  alt=""
                />
                <p>《国潮故宫冰嬉图5》</p>
              </div>
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone6.png"
                  alt=""
                />
                <p>《国潮故宫冰嬉图6》</p>
              </div>
            </div>
            <div class="img-cont">
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone7.png"
                  alt=""
                />
                <p>《国潮故宫冰嬉图7》</p>
              </div>
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone8.png"
                  alt=""
                />
                <p>《国潮故宫冰嬉图8》</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="phone-third">
        <div class="container-cont">
          <div class="content-towImg">
            <img
              class="circle-towImg"
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/circle-one.png"
              alt=""
            />
            <img
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-img-6.png"
              alt=""
              class="text-tow"
            />
          </div>
          <div class="content-first">
            <div class="cont-text">
              <p class="cont-h1">玩法一</p>
              <p class="cont-h2">3张同款静态图合成该款动态图</p>
            </div>
            <div class="cont-Img">
              <img
                class="cont-imgOne"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/fs-oneImg.png"
                alt=""
              />
              <img
                class="cont-imgTow"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/fs-oneImg.png"
                alt=""
              />
              <img
                class="cont-imgThree"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/fs-oneImg.png"
                alt=""
              />
            </div>
            <div class="down-oneImg">
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-img7.png"
                alt=""
              />
            </div>
            <div class="cont-fourImg">
              <div class="cont-contImg">
                <img
                  class="four-imgsmall"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/four-imgsmall.png"
                  alt=""
                />
                <img
                  class="gif-oneImg"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/gif-oneImg.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="phone-fourth">
        <div class="container-cont">
          <div class="content-first">
            <div class="cont-text">
              <p class="cont-h1">玩法二</p>
              <p class="cont-h2">8张不同款动态图合成1张完整动态大图</p>
            </div>
            <div class="cont-Img">
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone18.png"
                  alt=""
                />
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone15.png"
                  alt=""
                />
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phon16.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone11.png"
                  alt=""
                />
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone17.png"
                  alt=""
                />
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone14.png"
                  alt=""
                />
              </div>
              <div class="gif-contImg">
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phon12.png"
                  alt=""
                />
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phon13.png"
                  alt=""
                />
                <div class="git-cont">
                  <img
                    class="gif-bigImg"
                    src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/gif-bigImg.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!-- <div class="gif-pic">
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/gif-pic.png"
                alt=""
              />
            </div> -->
          </div>
        </div>
      </div>
      <div class="phone-fifth">
        <div class="container-cont">
          <div class="content-first">
            <div class="down-oneImg">
              <img
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-img7.png"
                alt=""
              />
            </div>
            <div class="qc-contImg">
              <img
                class="qc-img"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/qt-img.png"
                alt=""
              />
              <img
                class="gif-oneImg"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/gif-oneImg.png"
                alt=""
              />
            </div>
            <div class="foot-contBanner">
              <div class="foot-contImg">
                <div>
                  <img
                    class="phone-foot2"
                    src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-foot2.png"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    class="phone-foot1"
                    src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-foot1.png"
                    alt=""
                  />
                </div>
                <div class="wx-code">
                  <img
                    class="phone-wx"
                    src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/phone-wx.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="phone-sixth"></div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import "@/assets/css/base.scss";
import "@/assets/css/rem/index.scss";
import "@/assets/css/rem/indexAdapter.scss";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        mousewheelControl: true,
        direction: "vertical",
        observer: true,
      },
      contimgList: [
        {
          title: "《国潮故宫冰嬉图1》",
          src: "https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-11.png",
        },
        {
          title: "《国潮故宫冰嬉图3》",
          src: "https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-13.png",
        },
        {
          title: "《国潮故宫冰嬉图2》",
          src: "https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-12.png",
        },
        {
          title: "《国潮故宫冰嬉图4》",
          src: "https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-14.png",
        },
        {
          title: "《国潮故宫冰嬉图5》",
          src: "https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-15.png",
        },
        {
          title: "《国潮故宫冰嬉图7》",
          src: "https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-17.png",
        },
        {
          title: "《国潮故宫冰嬉图6》",
          src: "https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-16.png",
        },

        {
          title: "《国潮故宫冰嬉图8》",
          src: "https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/pc-18.png",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.page-index {
  font-family: SourceHanSerifSC-VF !important;
  .swiper {
    background: url("https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/tow-bg.png")
      no-repeat center center;
    background-size: 100% 100%;
    height: 100vh;
    .content-first {
      width: 1200px;
      margin: 0 auto;
    }
    .content-towImg {
      margin: 47px 0 0 137px;
      .circle-towImg {
        vertical-align: super;
        margin: 0 15px 10px 0;
      }
    }
  }
  .swiper {
    background-color: #e6e3eb;
    background-size: cover;
    height: 100vh;

    .swiper-slide-first {
      display: flex;
      align-items: flex-start;
      height: 100vh;
      position: relative;
      background: url("https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/one-bg.png")
        no-repeat center center;
      background-size: 100% 100%;
      .content-oneImg {
        margin: 63px 0 0 150px;
        // display: flex;
      }
      .content-first {
        height: 100vh;
        display: flex;
        justify-content: space-around;
        width: 1200px;
        margin-top: 125px;
        .text-circle {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          border: 5px solid #9d1420;
          opacity: 0.8;
          display: flex;
        }
        .text-oneImg {
          margin-right: 33px;
        }
        .cont-text {
          writing-mode: vertical-lr;
          width: 268px;
          // height: 537px;
          font-size: 20px;
          font-weight: bold;
          line-height: 54px;
          letter-spacing: 10px;
          .cont-textOne {
            margin-right: 40px;
          }
        }
      }
    }
    .swiper-slide-second {
      .content-first {
        display: flex;
        justify-content: space-around;
        margin-top: 55px;
        p {
          text-align: center;
          margin: 0 0 30px 0;
          font-weight: 900;
          font-size: 26px;
        }
      }
      height: 100vh;
    }
  }
  .swiper-slide-three {
    height: 100vh;
    .content-first {
      display: flex;
      justify-content: space-around;
      margin-top: 85px;
      p {
        text-align: center;
        // margin: 25px 0 69px 0;
        font-weight: 900;
        font-size: 26px;
      }
    }
  }
  .swiper-slide-four {
    height: 100vh;
    .content-towImg {
      margin: 47px 0 62px 137px;
    }
    .content-first {
      text-align: center;
      p {
        font-size: 26px;
        margin: 0 0 58px 0;
        font-weight: bold;
        span {
          font-size: 35px;
          font-weight: 900;
          padding-right: 43px;
        }
      }
      .cont-Img {
        height: 260px;
        position: relative;
        left: 27%;
        img {
          position: absolute;
          top: 0;
          width: 338px;
          height: 259px;
        }
        .cont-imgOne {
          left: 0;
        }
        .cont-imgTow {
          left: 120px;
        }
        .cont-imgThree {
          left: 240px;
        }
      }
      .cont-fourImg {
        position: relative;
        .right-oneImg {
          position: absolute;
          left: 30%;
          top: 35%;
        }
        .gif-oneImg {
          margin-bottom: 15px;
        }
        .cont-contImg {
          margin-left: 215px;
        }
        .four-imgsmall {
          width: 338px;
          height: 259px;
        }
      }
    }
  }
  .swiper-slide-five {
    height: 100vh;
    background-size: 100% 100%;
    .content-first {
      padding-top: 80px;
      p {
        font-size: 26px;
        margin: 30px 0 58px 0;
        text-align: center;
        font-weight: bold;
        span {
          font-size: 35px;
          font-weight: 900;
          padding-right: 43px;
        }
      }
      .cont-Img {
        text-align: center;
        img {
          margin: 0 48px 25px 0;
          width: 230px;
          height: 177px;
        }
        .gif-contImg {
          display: flex;
          justify-content: center;
          .git-cont {
            width: 230px;
            height: 177px;
            margin: 0 48px 50px 0;
            .gif-bigImg {
              height: 95px;
              width: 95px;
              margin-top: 45px;
            }
          }
        }
      }
    }
  }
  .swiper-slide-six {
    height: 100vh;
    .content-first {
      text-align: center;
      margin-top: 80px;
      .qt-img {
        margin-left: 51px;
      }
      .gif-bigImg {
        margin: 0 0 50px 50px;
      }
      .down-oneImg {
        margin: 30px 0 50px 0;
      }
      .foot-contImg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 150px;
        img {
          padding-left: 40px;
        }
      }
      .wx-code {
        width: 110px;
        height: 110px;
        border: 2px solid #000000;
        border-radius: 5px;
        margin-left: 60px;
        img {
          padding: 5px;
        }
      }
    }
  }
}
.pc-class {
  display: block;
}
.phone-class {
  display: none;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 560px) {
  .phone-class {
    font-family: SourceHanSerifSC-VF !important;
    background-color: #eae7f0;
    background: url("https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/bg_02.jpg")
      no-repeat center center;
    background-size: 100% 100%;
    .content-towImg {
      padding: 0.5rem 0 0 0.15rem;
      .circle-towImg {
        height: 0.44rem;
        width: 0.44rem;
        margin: 0 0.2rem 0.1rem 0;
      }
      .text-tow {
        height: 0.63rem;
        width: 2.67rem;
      }
    }
    .content-first {
      height: 100%;
      margin: 0 auto;
    }
    .phone-first {
      background: url("https://theoneart-public.oss-cn-qingdao.aliyuncs.com/activity/gugong/bg_01.jpg")
        no-repeat center center;
      background-size: cover;
      .container-cont {
        .content-first {
          display: flex;
          justify-content: space-around;
          padding: 2rem 0.6rem 0.75rem 0;
          .cont-text {
            writing-mode: vertical-lr;
            // height: 3.75rem;
            // width: 2.74rem;
            letter-spacing: 5px;
            font-size: 0.22rem;
            font-family: SourceHanSerifSC-VF !important;
            font-weight: bold;
            line-height: 0.38rem;
            // padding-left: 3.8rem;
            p {
              margin-right: 0.1rem;
            }
            .cont-textOne {
              margin-right: 0.3rem;
            }
          }
          .phone-textOne {
            height: 4.06rem;
            width: 0.5rem;
          }
          .seal-tow {
            width: 0.31rem;
            height: 0.77rem;
            margin-left: 0.16rem;
          }
        }
        .content-oneImg {
          padding: 0.5rem 0 0.83rem 0.5rem;
          float: left;
          img{
            height: 0.86rem;
            width: 0.63rem;
          }
        }
      }
    }

    .phone-second {
      height: 100%;

      .content-first {
        margin-top: 0.6rem;
        .img-cont {
          display: flex;
          margin-left: 0.6rem;
        }
        img {
          width: 2.5rem;
          height: 1.82rem;
          margin-left: 0.35rem;
          margin-right: 0.35rem;
        }
        p {
          text-align: center;
          margin: 0 0 0.2rem 0;
          font-weight: 600;
          font-size: 0.22rem;
        }
      }
    }
    .phone-third {
      height: 100%;
      .cont-text {
        margin-top: 0.7rem;
        .cont-h1 {
          margin-bottom: 0.23rem;
          font-size: 0.28rem;
          font-weight: 900;
        }
        .cont-h2 {
          font-size: 0.22rem;
          font-weight: bold;
          margin-bottom: 0.42rem;
        }
      }
      .down-oneImg {
        margin: 0.1rem 0 0.3rem 0;
        img {
          width: 0.5rem;
          height: 1rem;
        }
      }
      .content-first {
        text-align: center;
        p {
          font-size: 0.22rem;
          // margin: 0 0 0.58rem 0;
          font-weight: bold;
          span {
            font-size: 0.35rem;
            font-weight: 900;
            padding-right: 0.43rem;
          }
        }
        .cont-Img {
          position: relative;
          // left: 27%;
          height: 2.43rem;
          margin-left: 1rem;
          img {
            position: absolute;
            top: 0;
            width: 3.17rem;
            height: 2.43rem;
          }
          .cont-imgOne {
            left: 0;
          }
          .cont-imgTow {
            left: 1.2rem;
          }
          .cont-imgThree {
            left: 2.4rem;
          }
        }
        .cont-fourImg {
          position: relative;
          .right-oneImg {
            position: absolute;
            left: 30%;
            top: 35%;
          }
          .gif-oneImg {
            margin-bottom: 0.7rem;
            height: 0.67rem;
            width: 0.67rem;
          }

          .four-imgsmall {
            width: 3.16rem;
            height: 2.42rem;
            margin-right: 0.64rem;
          }
        }
      }
    }
    .phone-fourth {
      height: 100%;
      .content-first {
        text-align: center;
        padding-top: 0.7rem;
        .cont-Img {
          text-align: center;
          div {
            img {
              width: 2.3rem;
              height: 1.8rem;
            }
          }
          .gif-contImg {
            display: flex;
            justify-content: center;
          }
          .git-cont {
            width: 2.3rem;
            height: 1.8rem;
            padding-top: 0.5rem;
            .gif-bigImg {
              height: 0.67rem;
              width: 0.67rem;
            }
          }
        }
        .gif-pic {
          img {
            height: 5.61rem;
            height: 4.63rem;
          }
        }
      }
      .cont-text {
        // padding-top: 0.5rem;
        .cont-h1 {
          margin-bottom: 0.23rem;
          font-size: 0.28rem;
          font-weight: 900;
        }
        .cont-h2 {
          font-size: 0.22rem;
          font-weight: bold;
          margin-bottom: 0.42rem;
        }
      }
    }
    .phone-fifth {
      height: 100%;
      .container-cont {
        .down-oneImg {
          padding: 0.1rem 0 0.3rem 0;
          text-align: center;
          img {
            width: 0.5rem;
            height: 1rem;
          }
        }
        .qc-contImg {
          text-align: center;
          .qc-img {
            width: 4.85rem;
            height: 1.43rem;
          }
          .gif-oneImg {
            height: 0.5rem;
            width: 0.5rem;
            margin: 0 0 0.5rem 0.2rem;
          }
        }
        .foot-contBanner {
          .foot-contImg {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 0 0.6rem 0;
            img {
              margin-right: 0.4rem;
            }
          }
          .phone-foot2 {
            height: 0.17rem;
            width: 1.69rem;
          }
          .phone-foot1 {
            height: 0.5rem;
            width: 0.62rem;
          }
          .wx-code {
            .phone-wx {
              height: 0.66rem;
              width: 0.66rem;
              padding: 0.04rem;
            }
            height: 0.69rem;
            width: 0.69rem;
            border: 1px solid #000000;
            border-radius: 3px;
          }
        }
      }
    }
  }
  .pc-class {
    display: none;
  }
  .phone-class {
    display: block;
  }
}
</style>
